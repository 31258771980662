import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/layout";
import React, { FC } from "react";
import { Card } from "../Card";

// @ts-ignore
import scooterRepair from "../../../images/scooter-repair.jpg";

// @ts-ignore
import carRepair from "../../../images/car-repair.jpg";

// @ts-ignore
import workShop from "../../../images/mulles-workshop.jpg";
import { Image } from "@chakra-ui/image";

export const AboutContent: FC = () => {
  return (
    <Box py={10} height="100%">
      <Heading mb={7} textAlign="center">
        Dit lokale værksted
      </Heading>
      <SimpleGrid
        p={5}
        py={10}
        spacingX="40px"
        spacingY="40px"
        justifyContent="center"
        columns={{ base: 1, md: 2 }}
        alignItems="center"
      >
        <Box mx={{ base: "auto", md: " auto" }} position="relative">
          <Image
            rounded={"lg"}
            w={250}
            h={250}
            objectFit="contain"
            src={scooterRepair}
          />
        </Box>
        <Box mx={{ base: "auto", md: 0 }} maxW="400px">
          <Heading my={5} textAlign="center" size="lg">
            Ingen opgaver for små
          </Heading>
          <Text>
            Ingen opgaver er for små for Mulles Plade & Autoværksted. Har du
            problemer med knægtens knallert, eller driller motoren på
            plæneklipperen? Så kom et smut forbi værkstedet, så finder vi helt
            sikkert en løsning på det.
          </Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        p={5}
        py={10}
        spacingX="40px"
        spacingY="40px"
        justifyContent="center"
        columns={{ base: 1, md: 2 }}
        alignItems="center"
      >
        <Box mx={{ base: "auto", md: "auto" }} maxW="400px">
          <Heading my={5} textAlign="center" size="lg">
            Ingen opgaver for store
          </Heading>
          <Text>
            Selvom Mulle gerne lapper et punkteret cykeldæk, så sætter det ikke
            en stopper for at udføre reperationer, på alle slags bilmærker.
            Værkstedet er toptunet, og har udstyret til både service af
            aircondition, og meget mere.
          </Text>
        </Box>
        <Box mx={{ base: "auto", md: " auto" }} position="relative">
          <Image
            rounded={"lg"}
            w={400}
            h={250}
            objectFit="contain"
            src={carRepair}
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};
