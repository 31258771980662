import { Box, Flex, HStack, Stack, VStack } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { Collapse } from "@chakra-ui/transition";
import React, { FC } from "react";
import { Contact } from "./Contact";
import { HeaderButton } from "./HeaderButton";
import { Logo } from "./Logo";
import { useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

interface MobileNavItemProps {
  scrollToSelector: string;
  buttonText: string;
}

export const Header: FC = () => {
  const isSmall = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onToggle } = useDisclosure();

  const MobileNavItem: FC<MobileNavItemProps> = ({
    scrollToSelector,
    buttonText,
  }) => {
    return (
      <Box onClick={onToggle}>
        <HeaderButton
          scrollToSelector={scrollToSelector}
          buttonText={buttonText}
        />
      </Box>
    );
  };

  const MobileNav = () => {
    return (
      <Flex
        alignItems="center"
        flexDir="column"
        bgColor="#2F2E2E"
        p={4}
        display={{ base: "flex", lg: "none" }}
        height="100%"
      >
        <MobileNavItem
          scrollToSelector="#service-section"
          buttonText="Service"
        />
        <MobileNavItem
          scrollToSelector="#about-section"
          buttonText="Om værkstedet"
        />
        <MobileNavItem
          scrollToSelector="#insurance-section"
          buttonText="Forsikringsskader"
        />
        <MobileNavItem
          scrollToSelector="#contact-section"
          buttonText="Kontakt"
        />
      </Flex>
    );
  };

  return (
    <Box
      position="sticky"
      zIndex="100"
      top="0"
      width="100%"
      height="100px"
      as="header"
      bgColor="#2F2E2E"
    >
      <Box
        mx="auto"
        pr="15px"
        pl="15px"
        width={{ base: "100%", md: "720px", lg: "960px", xl: "1140px" }}
        py="auto"
        display="flex"
        alignItems="center"
        height="100%"
        justifyContent="space-between"
      >
        <Logo />
        {!isSmall && (
          <>
            <HStack>
              <HeaderButton
                scrollToSelector="#service-section"
                buttonText="Service"
              />
              <HeaderButton
                scrollToSelector="#about-section"
                buttonText="Om værkstedet"
              />
              <HeaderButton
                scrollToSelector="#insurance-section"
                buttonText="Forsikringsskader"
              />
              <HeaderButton
                scrollToSelector="#contact-section"
                buttonText="Kontakt"
              />
            </HStack>
            <Box>
              <Contact />
            </Box>
          </>
        )}
        {isSmall && (
          <>
            <IconButton
              bgColor="transparent"
              _hover={{ bgColor: "transparent" }}
              _focus={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon color="white" w={3} h={3} />
                ) : (
                  <HamburgerIcon color="white" w={5} h={5} />
                )
              }
              aria-label={"Toggle Navigation"}
            />
          </>
        )}
      </Box>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav></MobileNav>
      </Collapse>
    </Box>
  );
};
