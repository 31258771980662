import Icon from "@chakra-ui/icon";
import { HStack, Text } from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiInfo, FiMapPin } from "react-icons/fi";

interface Props {
  textColor?: string;
}

export const CVR: FC<Props> = ({ textColor }) => {
  return (
    <HStack color={textColor ? textColor : "white"}>
      <Icon as={FiInfo} color="green.500" />
      <Text>CVR: 41575417</Text>
    </HStack>
  );
};
