import Icon from "@chakra-ui/icon";
import { Image } from "@chakra-ui/image";
import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Link } from "gatsby";
import { FiMail, FiPhone } from "react-icons/fi";

import React, { FC } from "react";
import { Email } from "../Contact/Email";
import { Phone } from "../Contact/Phone";

export const Contact: FC = () => {
  return (
    <HStack>
      <Box>
        <VStack color="white" spacing="10px" alignItems="flex-start">
          <Email />
          <Phone />
        </VStack>
      </Box>
    </HStack>
  );
};
