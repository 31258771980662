import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import {
  Box,
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/layout";
import React, { FC } from "react";
import { Address } from "../../Contact/Address";
import { Email } from "../../Contact/Email";
import { Phone } from "../../Contact/Phone";
import { Contact } from "../../Header/Contact";

import emailjs from "emailjs-com";
import { useToast } from "@chakra-ui/toast";

export const ContactContent: FC = () => {
  const toast = useToast();
  const handleSubmit = (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const phone = event.target.phone.value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    const emailData = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };

    emailjs.init("user_SKRHo5Fvy1Zh8ygm6ZhvB");

    emailjs
      .send(
        "service_7e8k5co",
        "template_luiti1o",
        emailData,
        "user_SKRHo5Fvy1Zh8ygm6ZhvB"
      )
      .then(
        (result) => {
          toast({
            title: "Email er sendt",
            position: "bottom-right",
            description: "Du får et svar tilbage hurtigst muligt.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        (error) => {
          toast({
            title: "Fejl",
            description: "Din email blev ikke sendt, prøv igen.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Box p={{ base: 5, md: 10 }} height="100%">
      <Heading textAlign="center">Kontakt</Heading>

      <SimpleGrid mt={10} columns={{ base: 1, md: 2 }} spacingY="40px">
        <Box px={{ base: 0, md: 5, lg: 10 }}>
          <Text>
            Har jeg for travlt på telefonen, så skal du være velkommen til at
            sende mig en mail, ved at udfylde nedstående formular. Så vil jeg
            vende tilbage på din henvendelse hurtigst muligt.
          </Text>

          <VStack mt={5} alignItems={{ base: "center", md: "start" }}>
            <Phone textColor="black" />
            <Address textColor="black" />
          </VStack>

          <VStack
            mt={5}
            spacing={0}
            alignItems={{ base: "center", md: "start" }}
          >
            <Text fontWeight="bold">Åbningstider:</Text>
            <Text>
              Når det passer både dig og mig! <br></br>Ring eller skriv, så
              aftaler vi en tid.
            </Text>
          </VStack>
        </Box>

        <form id="my-form" onSubmit={handleSubmit}>
          <VStack spacing={5}>
            <FormControl id="name">
              <FormLabel>Navn</FormLabel>
              <Input type="text" required />
            </FormControl>
            <FormControl id="email">
              <FormLabel>E-mail</FormLabel>
              <Input type="email" required />
            </FormControl>
            <FormControl id="phone">
              <FormLabel>Mobilnummer</FormLabel>
              <Input type="phone" required />
            </FormControl>
            <FormControl id="message">
              <FormLabel>Besked</FormLabel>
              <Input as="textarea" height="200px" required />
            </FormControl>
            <Button
              colorScheme="facebook"
              width={{ base: "100%", md: "100px" }}
              alignSelf="end"
              type="submit"
            >
              Send
            </Button>
          </VStack>
        </form>
      </SimpleGrid>
      <Flex></Flex>
    </Box>
  );
};
