import { Button, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import {
  Box,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiFacebook } from "react-icons/fi";
import { Address } from "../Contact/Address";
import { Email } from "../Contact/Email";
import { Phone } from "../Contact/Phone";
import { FaFacebook, FaMotorcycle } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import {
  GiScooter,
  GiAutoRepair,
  GiMetalPlate,
  GiCarWheel,
} from "react-icons/gi";
import { Avatar } from "@chakra-ui/avatar";

// @ts-ignore
import mulle from "../../images/mulle.jpg";
import { CVR } from "../Contact/CVR";

export const Footer: FC = () => {
  return (
    <Box width="100%" py={10} minHeight="300px" as="footer" bgColor="#2F2E2E">
      <Box
        mx="auto"
        pr="15px"
        pl="15px"
        width={{ base: "100%", md: "720px", lg: "960px", xl: "1140px" }}
        display="flex"
        height="100%"
      >
        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2, lg: 4 }}
          spacingY="40px"
          justifyItems="center"
        >
          <VStack alignItems="start">
            <Address></Address>
            <Phone></Phone>
            <Email></Email>
            <CVR></CVR>
          </VStack>
          <Link href="https://www.facebook.com/Mulles-Plade-og-Autov%C3%A6rksted-111682500661944/">
            <Button colorScheme="facebook" leftIcon={<FaFacebook />}>
              Facebook
            </Button>
          </Link>
          <List color="white" spacing={3}>
            <Heading size="md">Din lokale specialist i</Heading>
            <ListItem>
              <ListIcon w={6} h={6} as={AiFillCar} color="green.500" />
              Alle bilmærker
            </ListItem>
            <ListItem>
              <ListIcon w={6} h={6} as={FaMotorcycle} color="green.500" />
              Motorcykler
            </ListItem>
            <ListItem>
              <ListIcon w={6} h={6} as={GiScooter} color="green.500" />
              Knallerter
            </ListItem>
            <ListItem>
              <ListIcon w={6} h={6} as={GiAutoRepair} color="green.500" />
              Forsikringsskader
            </ListItem>
            <ListItem>
              <ListIcon w={6} h={6} as={GiMetalPlate} color="green.500" />
              Alt pladearbejde
            </ListItem>
            <ListItem>
              <ListIcon w={6} h={6} as={GiCarWheel} color="green.500" />
              Hjul- og dækskift
            </ListItem>
          </List>

          <VStack>
            <Avatar size="xl" name="Mulle" src={mulle} />
            <Text color="white" fontWeight="bold">
              Carsten 'Mulle' Bjørndal Pedersen
            </Text>
            <Text textAlign="center" color="white">
              30 års erfaring indenfor pladearbejde og mekanik
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>
    </Box>
  );
};
