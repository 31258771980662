import { Box } from "@chakra-ui/layout";
import React, { FC, ReactNode } from "react";

interface ComponentChildren {
  children: ReactNode;
  elementSelector: string;
}

export const OverlaySection: FC<ComponentChildren> = ({
  children,
  elementSelector,
}) => {
  return (
    <Box
      style={{ scrollMarginTop: "100px" }}
      id={elementSelector}
      backgroundColor="rgba(54, 25, 25, 60%);"
    >
      {children}
    </Box>
  );
};
