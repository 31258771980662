import Icon from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiPhone } from "react-icons/fi";

interface Props {
  textColor?: string;
}

export const Phone: FC<Props> = ({ textColor }) => {
  return (
    <HStack color={textColor ? textColor : "white"}>
      <Icon color="green.500" as={FiPhone} />
      <a style={{ textDecoration: "underline" }} href="tel:+4561342161">
        +45 61 34 21 61
      </a>
    </HStack>
  );
};
