import Icon from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiMapPin } from "react-icons/fi";

interface Props {
  textColor?: string;
}

export const Address: FC<Props> = ({ textColor }) => {
  return (
    <HStack color={textColor ? textColor : "white"}>
      <Icon color="green.500" as={FiMapPin} />
      <a
        style={{ textDecoration: "underline" }}
        href="https://maps.app.goo.gl/HHU4zanTiEY3VYkTA"
      >
        Bødkervænget 11 A , <br></br>Sæby 4270 Høng

        
      </a>
    </HStack>
  );
};
