import { Image } from "@chakra-ui/image";

import React, { FC } from "react";

// @ts-ignore
import bgImage from "../../images/mulle-background-image1.jpg";

export const PageBackgroundImage: FC = () => {
  return (
    <Image
      position="fixed"
      objectFit="cover"
      top="0px"
      left="0px"
      zIndex="-1"
      minW="100%"
      minH="100%"
      src={bgImage}
      bgPosition="center"
      overflowY="hidden"
    />
  );
};
