import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Heading, Link, SimpleGrid, VStack } from "@chakra-ui/layout";
import scrollTo from "gatsby-plugin-smoothscroll";
import React, { FC } from "react";
import { FiPhone } from "react-icons/fi";

export const HeadingContent: FC = () => {
  const PhoneIcon = <Icon as={FiPhone}></Icon>;
  return (
    <VStack py={10} spacing={20}>
      <Heading size="2xl" mt={10} color="white" textAlign="center">
        MULLES PLADE & AUTOVÆRKSTED
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="40px" spacingY="40px">
        <Button
          onClick={() => scrollTo("#contact-section", "start")}
          borderRadius="0px"
          minWidth="200px"
          fontSize="25px"
          py="35px"
          px="25px"
          bgColor="transparent"
          color="white"
          boxShadow="0px 0px 0px 4px white inset"
          _hover={{
            bgColor: "white",
            textColor: "black",
          }}
        >
          Kontakt
        </Button>
        <Link _hover={{ textDecoration: "none" }} href="tel:+4561342161">
          <Button
            minWidth="200px"
            color="white"
            _hover={{ bgColor: "rgba(186, 218, 85, 0.6)" }}
            bgColor="rgba(186, 218, 85, 0.80)"
            fontSize="25px"
            borderRadius="0px"
            py="35px"
            px="25px"
            textDecoration="none"
            leftIcon={PhoneIcon}
          >
            +45 61 34 21 61
          </Button>
        </Link>
      </SimpleGrid>
    </VStack>
  );
};
