import Icon from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiMail } from "react-icons/fi";

interface Props {
  textColor?: string;
}

export const Email: FC<Props> = ({ textColor }) => {
  return (
    <HStack color={textColor ? textColor : "white"}>
      <Icon color="green.500" as={FiMail} />
      <a
        style={{ textDecoration: "underline" }}
        href="mailto:mulles-auto@outlook.dk"
      >
        mulles-auto@outlook.dk
      </a>
    </HStack>
  );
};
