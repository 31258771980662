import { Box, Container } from "@chakra-ui/layout";
import { ChakraProvider } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";
import { Header } from "../components/Header/Header";
import { PageBackgroundImage } from "../../src/components/PageComponents/PageBackgroundImage";
import { Footer } from "../components/Footer/Footer";

interface LayoutProps {
  children: ReactNode;
}

export const LayoutWithHeader: FC<LayoutProps> = ({ children }) => {
  return (
    <ChakraProvider>
      <Header />
      <Box mx="auto" width={{ base: "100%", md: "720px", lg: "960px" }}>
        {children}
      </Box>
      <PageBackgroundImage />
      <Footer />
    </ChakraProvider>
  );
};
