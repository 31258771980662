import { Button } from "@chakra-ui/button";
import { Link } from "@chakra-ui/layout";
import { Link as ReachLink } from "@reach/router";
import React, { FC } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

interface ButtonProps {
  buttonText: string;
  scrollToSelector: string;
}

export const HeaderButton: FC<ButtonProps> = ({
  buttonText,
  scrollToSelector,
}) => {
  return (
    <Button
      fontWeight="bold"
      fontSize="1.2em"
      height="60px"
      borderRadius="0"
      bgColor="transparent"
      onClick={() => scrollTo(scrollToSelector, "start")}
      color="white"
      _hover={{ color: "darkgrey" }}
    >
      {buttonText}
    </Button>
  );
};
