import Icon from "@chakra-ui/icon";
import { Image } from "@chakra-ui/image";
import {
  Box,
  Flex,
  Heading,
  HStack,
  List,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from "@chakra-ui/layout";
import React, { FC } from "react";
import { FiCheck } from "react-icons/fi";

// @ts-ignore
import workShopImg from "../../../images/workshop.jpg";

// @ts-ignore
import warrantyIcon from "../../../images/reservedele-garanti.png";

// @ts-ignore
import insuranceImage from "../../../images/insurance-image.jpg";

export const ServiceContent: FC = () => {
  const services: string[] = [
    "Aircondition service",
    "Diverse bilreperationer",
    "Auto El & Eletrisk arbejde",
    "Bilbatteri",
    "Bilpleje, rens & polering",
    "Bilsyn & omsyn",
    "Biltilbehør & ekstraudstyr",
    "Bremser & bremseskiver",
    "Dæk, hjul og hjulskifte",
    "Dækopbevaring",
    "Fælge",
    "Gearkasse",
    "Kobling",
    "Motor",
    "Olieskift",
    "Pladearbejde & buler",
    "Rustarbejde",
    "Serviceeftersyn",
    "Skadesarbejde",
    "Sommerdæk",
    "Stenslag & glasarbejde",
    "Styretøj & ratslør",
    "Støddæmpere & affjedring",
    "Tandrem og taktkæde",
    "Toppakning",
    "Udstødning og katalysator",
    "Vinterdæk",
  ];

  const listItem = () => {
    return (
      <UnorderedList>
        <ListItem></ListItem>
      </UnorderedList>
    );
  };

  return (
    <Box p={{ base: 5, md: 10 }} height="100%">
      <SimpleGrid mt={5} py={10} columns={{ base: 1, md: 2 }} spacingY="40px">
        <Box mx={{ base: "auto", md: 0 }} maxW="400px">
          <Heading my={5} textAlign="center" size="lg">
            Service
          </Heading>
          <Text color="black" fontSize="1em">
            Mulles Plade & Autoværksted er dit lokale værksted. Her kan der
            klares alt ligefra et standard serviceeftersyn, reperation af alle
            bilmærker, eller pladearbejde som f.eks. buler eller rust. Du kan
            altid regne med en kvalitet i særklasse og en service derefter.
          </Text>
        </Box>
        <Flex justifyContent={{ base: "center" }}>
          <Image borderRadius="5px" h={250} src={workShopImg} />
        </Flex>
      </SimpleGrid>
      <Box py={10}>
        <Heading my={8} textAlign="center" size="lg">
          Hos Mulles tilbydes der service indenfor
        </Heading>
        <SimpleGrid
          maxWidth="fit-content"
          mx="auto"
          spacingX="40px"
          columns={{ base: 2, md: 2, lg: 3 }}
        >
          {services.map((service) => {
            return (
              <Box>
                <HStack width="100%">
                  <Icon as={FiCheck} color="green.500"></Icon>
                  <Text
                    fontSize={{ base: "0.9em", md: "1em" }}
                    textAlign="start"
                    color="black"
                  >
                    {service}
                  </Text>
                </HStack>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>

      <SimpleGrid
        py={10}
        spacingY="40px"
        spacingX="40px"
        alignItems="center"
        columns={{ base: 1, md: 2 }}
        mt={{ base: "40px", md: "80px" }}
      >
        <Box mx={{ base: "auto", md: 0 }} maxWidth="350px" position="relative">
          <Image borderRadius="5px" src={insuranceImage}></Image>
          <Image
            display={{ base: "none", md: "block" }}
            position="absolute"
            top="-10"
            left="90%"
            width="90px"
            height="90px"
            src={warrantyIcon}
          />
        </Box>
        <Box mx={{ base: "auto", md: 0 }} maxW="400px">
          <Heading my={5} textAlign="center" size="lg">
            Garantien er i orden
          </Heading>
          <Text color="black">
            Certificeret autoværksted, med 1 års garanti på alle reservedele.
            Her er du altid sikret kvalitet på det udførte arbejde.<br></br>
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
