import { Box } from "@chakra-ui/layout";
import { BackgroundProps } from "@chakra-ui/styled-system";
import React, { FC, ReactNode } from "react";

interface ComponentChildren {
  children: ReactNode;
  bgColor: BackgroundProps["backgroundColor"];
  elementSelector: string;
}

export const Section: FC<ComponentChildren> = ({
  children,
  bgColor,
  elementSelector,
}) => {
  return (
    <Box
      style={{ scrollMarginTop: "100px" }}
      id={elementSelector}
      height="100%"
      backgroundColor={bgColor}
    >
      {children}
    </Box>
  );
};
