import { Box, Text } from "@chakra-ui/layout";
import * as React from "react";
import { Footer } from "../components/Footer/Footer";
import { OverlaySection } from "../components/Section/OverlaySection";
import { Section } from "../components/Section/Section";
import { AboutContent } from "../components/Section/SectionContent/About";
import { ContactContent } from "../components/Section/SectionContent/Contact";
import { HeadingContent } from "../components/Section/SectionContent/Heading";
import { InsuranceContent } from "../components/Section/SectionContent/Insurance";
import { ServiceContent } from "../components/Section/SectionContent/Services";
import { LayoutWithHeader } from "../layouts/LayoutWithHeader";

const IndexPage: React.FC = () => {
  return (
    <LayoutWithHeader>
      <OverlaySection elementSelector="header-section">
        <HeadingContent />
      </OverlaySection>
      <Section elementSelector="service-section" bgColor="rgb(227, 227, 227);">
        <ServiceContent />
      </Section>
      <Section elementSelector="about-section" bgColor="white">
        <AboutContent />
      </Section>
      <Section
        elementSelector="insurance-section"
        bgColor="rgb(227, 227, 227);"
      >
        <InsuranceContent />
      </Section>
      <Section elementSelector="contact-section" bgColor="white">
        <ContactContent />
      </Section>
    </LayoutWithHeader>
  );
};

export default IndexPage;
