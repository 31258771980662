import { Box, Heading, Text } from "@chakra-ui/layout";
import React, { FC } from "react";

export const InsuranceContent: FC = () => {
  return (
    <Box p={10} height="100%">
      <Heading mb={10} textAlign="center">
        Forsikringsskader
      </Heading>
      <Text>
        Hos Mulle får du et kompetent autoværksted, som har massere erfaring med
        forsikringsskader på biler. Her er der mange års erfaring med at
        håndtere forsikringssager for kunder, og han ordner også gerne skaderne
        på din bil. ​Mulle har både uddannelsen og efteruddannelsen til at
        håndtere alle skader, og der er naturligvis garanti på både reservedele
        og reparation. Dermed er du sikret, at din bil får en god behandling.
      </Text>
      <Text mt={5}>
        Mulle kender hele proceduren i forbindelse med en forsikringsskade, og
        står gerne til rådighed med hjælp og vejledning, når kontakten til
        forsikringsselskabet skal klares og diverse dokumenter skal udfyldes,
        han har nemlig prøvet det mange gange før.
      </Text>
    </Box>
  );
};
