import { Image } from "@chakra-ui/image";
import { Box, Text } from "@chakra-ui/layout";
import scrollTo from "gatsby-plugin-smoothscroll";
import React, { FC } from "react";

// @ts-ignore
import logoImage from "../../images/logo-clean.png";

export const Logo: FC = () => {
  return (
    <Box>
      <Image
        style={{ cursor: "pointer" }}
        onClick={() => scrollTo("#header-section")}
        height={{ base: "50px", md: "60px" }}
        src={logoImage}
      />
    </Box>
  );
};
